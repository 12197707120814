.form-control {
    line-height: 45px !important;
    @media only screen and (max-width: 575px) {
        line-height: 30px !important;
        margin-bottom: 0 !important;
    }
    //   width: 150% !important;
}

.row>* {
    @media only screen and (max-width: 575px) {
        margin-top: 5px !important;
    }
}

select#floatingInputGrid {
    padding-top: 11px;
}

span.ant-select-selection-placeholder {
    color: black !important;
    font-weight: 250;
    padding-top: 10px;
    font-size: 18px;
}

label.form-label {
    width: 100%;
}

.react-tel-input .form-control {
    height: 60px !important;
    width: 100% !important;
    font-size: 16px !important;
    @media only screen and (max-width: 414px) {
        height: 50px !important;
    }
}

.country-dropdown {
    width: 80px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height: 59px;
}

.country-input {
    border-left: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.country-input:focus {
    border: 1px solid #ced4da !important;
}

.nav-link {
    cursor: pointer;
}

.ant-select-selector {
    border: none !important;
}

//margins
.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

//buttons
button.btn.btn-dark.blue {
    background-image: linear-gradient(93deg,#9EC37F,#557C34) !important;
    border-color: transparent !important;
}

.upload-controls-container {
    //   justify-content: space-evenly;
    //   flex-wrap: wrap;
    //   display: flex;
}

.upload-controls {
    display: flex;
    /* flex-direction: column; */
    /* background: rgba(217, 217, 217, 0.3490196078); */
    /* padding: 13px; */
    /* border-radius: 10px; */
    /* margin-top: 15px; */
    /* border-style: dashed; */
    /* border-color: rgba(0, 0, 0, 0.168627451); */
    //   border-right: 1px solid #2125294a;
}

.upload-items {
    align-items: center;
    display: flex;
}

.uploads-list {
    margin-top: 10px;
}

.text-image {
    font-size: 9px;
    font-weight: 500;
    margin-left: 3px;
}

.text-image-container {
    display: flex;
}

.display-flex {
    display: flex;
}

label.phone-lable {
    position: absolute;
    top: 22%;
    z-index: 1;
    color: #212529;
    font-weight: 300;
    cursor: text;
    @media only screen and (max-width: 992px) {
        left: 77px;
    }
    @media only screen and (max-width: 768px) {
        top: 16%;
    }
    @media only screen and (max-width: 767px) {
        left: 77px;
    }
    @media only screen and (max-width: 557px) {
        left: 77px;
        //top: 30%;
    }
    @media only screen and (max-width: 414px) {
        left: 77px;
        top: 12%;
    }
    left: 77px;
}

.ar {
    @media only screen and (max-width: 557px) {
        flex-direction: column !important;
    }
}

.react-tel-input {
    margin-bottom: 8px;
}

.inquiry-form-control {
    /* height: 44px !important; */
    line-height: 45px !important;
    border-radius: 0.375rem;
    @media only screen and (max-width: 557px) {
        height: 44px !important;
    }
}

.header-pill {
    background: #6a9b41;
    color: white !important;
    font-size: 13px;
    border: 1px solid #6a9b41;
    border-radius: 6px;
    cursor: pointer;
    line-height: 15px;
    padding: 10px;
    margin-right: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.header-pill:hover {
    background: #6a9b41;
    color: white !important;
    font-size: 13px;
    border: 1px solid #6a9b41;
    border-radius: 6px;
    cursor: pointer;
    line-height: 15px;
    padding: 10px;
    margin-right: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.custom-d-inline-block {
    @media only screen and (max-width: 767px) {
        display: block;
        margin-top: 0px;
    }
}

.send-inq-date-picker {
    display: flex;
    justify-content: space-around;
    margin-bottom: 22px;
}

.rs-dropdown-toggle {
    background: none !important;
    margin-top: 2px !important;
    font-weight: 600 !important;
}

.rs-dropdown-item .active {
    background: rgb(16 141 255) !important;
    color: white !important;
}

.priceBig {
    display: flex;
    flex-direction: column;
    line-height: initial;
}

label.phone-lable.phone-d {
    top: 50%;
    left: 81px;
}
.toggle-cutsom {
    width: 100% !important;
    text-align: inherit !important;
    display: flex !important;
    justify-content: space-between !important;
    font-style: normal !important;
    font-weight: 500 !important;
    border: none !important;
  }
 /* Saudi visa css */

 