.mobilenav-div {
        position: absolute;
        background-color: red;
        right: 0;
        width: 50%;
        top: 5rem;
        display: flex;
        flex-direction: column;
        padding: 10px;
}

.parentnav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-dropdown {
            background-color: purple;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 10px;



            &-menuitems {
                display: flex;
                flex-direction: column;
            }
        }
}