.toggle-cutsom {
  width: 100% !important;
  text-align: inherit !important;
  display: flex !important;
  justify-content: space-between !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: none !important;
}
.margin-reset {
  margin-inline-start: unset !important;
}
