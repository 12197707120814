@import "../scss/variables.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.list-style-none {
  list-style: none !important;
}

$global-color: "#132f3b";

span {
  font-family: $font-monteserrat !important;
}

body {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: $font-monteserrat;
  font-weight: $font-weight-normal;
  overflow-x: hidden;

  // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  .navbar-brand {
    margin-right: 80px;

    @media (max-width: 1100px) {
      margin-right: 40px;
    }

    @media (max-width: 575px) {
      margin-right: 15px;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}

ol,
ul {
  padding-left: 0rem;
}

.color-primary {
  color: $primary-color;
}
.color-primary:hover {
  color: $primary-color;
}

.color-secondary {
  color: $secondary-color;
}

.bg-gray {
  background-color: $bg-gray;
}

.bg-white {
  background-color: $bg-color-white;
}

h1 {
  font-size: calc(20px + (48 - 20) * ((100vw - 320px) / (1920 - 320)));
}

h2 {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
}

h3 {
  font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
}

h4 {
  font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
}

h5 {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
}

h6 {
  font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
}

p {
  font-size: calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320)));
}

.font-size-18 {
  font-size: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.font-size-16 {
  font-size: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.font-size-14 {
  font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.font-size-12 {
  font-size: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
}

.font-size-10 {
  font-size: 10px;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-500 {
  font-weight: $font-weight-500;
}

.main-section {
  min-height: 100vh;
  margin-top: 95px;

  @media only screen and (max-width: 992px) {
    margin-top: 0px;
  }

  //@media only screen and (max-width: 767px) {
  //    margin-top: 88px;
  //}
  //
  //@media only screen and (max-width: 500px) {
  //    margin-top: 81px;
  //}
}

a {
  text-decoration: none !important;
}

.section-spacing {
  padding: 40px 0px 60px;

  @media only screen and (max-width: 992px) {
    padding: 60px 15px 40px;
  }

  @media only screen and (max-width: 767px) {
    padding: 40px 15px 30px;
  }

  @media only screen and (max-width: 557px) {
    padding: 30px 15px 20px;
  }

  .section-content {
    margin-bottom: 60px;

    @media only screen and (max-width: 992px) {
      margin-bottom: 40px;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 557px) {
      margin-bottom: 20px;
    }

    h6 {
      line-height: 28px;
    }
  }
}

.mb-30 {
  margin-bottom: 30px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 414px) {
    margin-bottom: 15px;
  }
}

.form-check-inline input[type="radio"] {
  height: 20px;
  width: 20px;
  display: none;
  cursor: pointer;
  position: relative;

  .active,
  :focus {
    outline: none;
  }
}

.custom-radio .form-check-label {
  padding: 6px;
  border-radius: 50px;
  background-color: $bg-color-white;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #808396;
  padding-left: 35px;
  padding-right: 15px;
  margin-right: 16px;

  @media only screen and (max-width: 575px) {
    font-size: 12px;
  }

  &::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #808396;
    left: 10px;
    top: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    left: 10px;
    right: auto;
    background-color: $bg-color-white;
    top: 8px;
    bottom: 0;
    transform: translate(50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
}

.custom-radio.checked-active .form-check-label {
  background-color: #808396;
  color: $bg-color-white;

  &::after {
    border-color: $bg-color-white;
  }

  &::before {
    display: block;
  }
}

.btn-primary {
  // background-color: $primary-color;
  background-image: linear-gradient(93deg, #9ec37f, #557c34) !important;
  padding: 13px 75px;
  font-weight: 600;
  font-size: 20px;
  border: 1px solid #53b2fe;
  border-radius: 36px;
  text-transform: uppercase;

  &:hover {
    background-image: linear-gradient(93deg, #9ec37f, #557c34) !important;
    border-color: linear-gradient(93deg, #9ec37f, #557c34) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// .travel-class-section {
//   display: none;
// }
// autocomplete input and flight sea
.flight-search-dropdown {
  z-index: 1111111;
  width: 270px !important;
  min-width: 270px !important;
  top: 560px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media only screen and (max-width: 1500px) {
    top: 555px !important;
    width: 225px !important;
    min-width: 225px !important;

    :where(.css-dev-only-do-not-override-1s3dcof).ant-select-dropdown
      .ant-select-item-option-grouped {
      padding-inline-start: 4px;
    }
  }

  @media only screen and (max-width: 1366px) {
    top: 555px !important;
    width: 210px !important;
    min-width: 210px !important;
  }

  @media only screen and (max-width: 1500px) {
    .ant-select-item-option-grouped {
      padding-left: 10px;
    }

    .ant-select-item-option-content {
      white-space: inherit;
    }
  }
}

.ant-popover {
  z-index: 999999;
}

.whatsapp-icon {
  width: 42px;

  @media only screen and (max-width: 992px) {
    width: 35px;
  }

  @media only screen and (max-width: 767px) {
    width: 35px;
  }

  @media only screen and (max-width: 414px) {
    width: 34px;
  }
}

.breadcrumb-section {
  background-image:  linear-gradient(120deg,#9EC37F,#557C34) !important;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0px;

  @media only screen and (max-width: 992px) {
    padding: 70px 0px;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 15px;
  }

  @media only screen and (max-width: 575px) {
    padding: 60px 15px;
    text-align: center;
  }

  .breadcrumb-title {
    font-size: 72px;
    font-size: calc(22px + (72 - 40) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(24px + (72 - 24) * ((100vw - 300px) / (1600 - 300)));
    color: $bg-color-white;
    font-weight: $font-weight-bold;
    font-family: "Montserrat";
  }

  .breadcrumb-desc {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 20px;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(20px + (30 - 20) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
  }
}
.breadcrumb-section-blog {
  background-image:  linear-gradient(120deg,#9EC37F,#557C34) !important;
  background-size: cover;
  background-repeat: no-repeat;
  height: 18rem;
}
.breadcrumb-section-dynamic {
  // background-image: url(../assets/images/hero-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0px 0px;
  height: 18rem;
  margin-top: 4rem;

  // @media only screen and (max-width: 992px) {
  //     padding: 70px 0px;
  // }

  // @media only screen and (max-width: 767px) {
  //     padding: 50px 15px;
  // }

  // @media only screen and (max-width: 575px) {
  //     padding: 30px 15px;
  //     text-align: center;
  // }
}
.pagination-list {
  .pagination {
    flex-wrap: wrap;

    .page-item {
      margin-right: 24px;

      .page-link {
        height: 36px;
        width: 36px;
        background: rgba(17, 141, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 500;
        font-size: 16px;
        color: $primary-color;

        &:hover {
          background-color: $primary-color;
          color: $bg-color-white;
        }
      }
    }
  }
}

.offcanvas {
  .offcanvas-body {
    padding: 0px;
    align-items: center;

    .nav-link {
      padding: 10px 0px;
      margin: 0px 15px;
      line-height: 45px;
      font-size: 16px;
      position: relative;
      color: #575757 !important;

      @media only screen and (max-width: 1200px) {
        font-size: 16px;
        font-size: 14px;
        line-height: 21px;
        margin: 0px 8px;
      }

      @media only screen and (max-width: 992px) {
        border-bottom: 1px solid #d8d8d8;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 4px;
        transition: transform 0.3s ease 0s;
        background-color: $primary-color;
        transform-origin: left bottom;
        transform: scale(0);

        // height: 3px;
        // width: 100%;
        // background-color: $bg-color-white;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: auto;
        // bottom: -16px;
        // transition: transform 0.3s ease 0s;
        // transform-origin: left bottom;
        @media only screen and (max-width: 1200px) {
          bottom: -32px;
        }
      }
    }

    .nav-link.active,
    &:hover {
      color: $primary-color !important;

      &:before {
        // background-color: $primary-color;
        transform: scale(1);
      }

      @media only screen and (max-width: 992px) {
        color: $primary-color;
      }
    }

    .bottom-header-right {
      margin-right: 175px;

      @media only screen and (max-width: 1100px) {
        margin-right: 115px;
      }

      .header-contact {
        background: rgba(17, 141, 255, 0.1);
        display: flex !important;
        align-items: center;
        background: rgba(17, 141, 255, 0.1);
        padding: 12px;
        //border: 2px dashed #6a9b41;
        border-radius: 6px;

        @media only screen and (max-width: 992px) {
          margin-top: 15px;
          border-bottom: 1px solid #d8d8d8;
          padding: 15px;
        }

        svg {
          margin-right: 15px;
          width: 30px;

          @media only screen and (max-width: 1100px) {
            width: 25px;
          }

          @media only screen and (max-width: 992px) {
            width: 18px;
          }
        }

        p {
          position: relative;
          width: auto;
          color: #1c2745;
          line-height: 12px;
          font-weight: 600;
          font-size: 12px !important;

          @media only screen and (max-width: 1100px) {
            font-size: 12px;
            width: auto;
          }

          @media only screen and (max-width: 767px) {
            font-size: 10px;
            top: -20px;
            display: block;
          }

          @media only screen and (max-width: 600px) {
            width: 250px;
          }
        }

        a {
          font-size: 34px;
          color: $primary-color;

          @media only screen and (max-width: 1440px) {
            font-size: 20px;
          }

          @media only screen and (max-width: 992px) {
            font-size: 18px;
          }

          @media only screen and (max-width: 575px) {
            font-size: 14px;
          }

          @media only screen and (max-width: 375px) {
            font-size: 12px;
          }
        }
      }

      .header-country {
        margin-left: 170px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1400px) {
          margin-left: 80px;
        }

        @media only screen and (max-width: 1100px) {
          margin-left: 50px;
        }

        @media only screen and (max-width: 992px) {
          margin-left: 70px;
        }

        @media only screen and (max-width: 767px) {
          margin-left: 0px;
          margin-top: 0px;
          padding: 15px;
        }

        img {
          margin-right: 15px;
        }

        select {
          border: none;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-checkbox-wrapper-checked:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1s3dcof).ant-checkbox-checked:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  background-color: black !important;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-checkbox
  .ant-checkbox-inner:after {
  width: 5px !important;
  left: 2px !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1s3dcof).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}

.modal-footer-logo {
  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    margin: auto;
  }
}

.modal-image-upload.ant-modal .ant-modal-close {
  top: 10px;
}

.modal-image-upload.ant-modal .ant-modal-body .upload-controls-container {
  max-height: calc(100vh - 200px) !important;
  margin-bottom: 30px;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.modal-image-upload.ant-modal .ant-modal-content {
  padding: 0;
}

.modal-image-upload.ant-modal .ant-modal-header {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.modal-image-upload.ant-modal .ant-modal-body {
  padding: 15px;
  padding-right: 0px;
  padding-top: 0px;
}

.modal-image-upload.ant-modal
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  margin: 0 20px 20px 0px;
}

// .image-container label {
//     margin-bottom: 0px;
// }
.modal-image-upload.ant-modal .footer-modal {
  padding: 0 24px;
  text-align: right;
}

.modal-image-upload.ant-modal
  .ant-upload-wrapper.ant-upload-picture-card-wrapper {
  display: inline;
}

.modal-image-upload.ant-modal .image-container {
  margin-right: 30px;

  @media only screen and (max-width: 557px) {
    margin-right: 10px;
  }

  @media only screen and (max-width: 414px) {
    margin-right: 5px;
  }

  label {
    @media only screen and (max-width: 414px) {
      font-size: 12px;
    }
  }
}

.modal-image-upload.ant-modal .image-container:last-child {
  margin-right: 0;
}

.modal-image-upload {
  @media only screen and (max-width: 1366px) {
    width: 63% !important;
  }

  @media only screen and (max-width: 1300px) {
    width: 70% !important;
  }

  @media only screen and (max-width: 992px) {
    width: 95% !important;
  }

  @media only screen and (max-width: 768px) {
    width: 98% !important;
  }
}

.modal-image-upload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  @media only screen and (max-width: 557px) {
    width: 80px !important;
    height: 80px !important;
  }

  @media only screen and (max-width: 414px) {
  }

  @media only screen and (max-width: 375px) {
    width: 80px !important;
    height: 80px !important;
  }

  @media only screen and (max-width: 320px) {
    width: 60px !important;
    height: 60px !important;
  }
}

.modal-image-upload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  @media only screen and (max-width: 557px) {
    width: 80px !important;
    height: 80px !important;
  }

  @media only screen and (max-width: 320px) {
    width: 60px !important;
    height: 60px !important;
  }
}

.rs-drawer-dialog {
  .send-inquiry-header {
    flex-wrap: wrap;
  }

  .rs-drawer-content {
    padding: 24px;
    right: 0;
    width: 1170px;

    @media only screen and (max-width: 1200px) {
      width: 900px;
    }

    @media only screen and (max-width: 992px) {
      width: 767px;
    }

    @media only screen and (max-width: 767px) {
      width: 90%;
    }

    @media only screen and (max-width: 675px) {
      width: 97%;
      padding: 15px;
    }

    .drawer-header {
      padding: 0;

      .back-btn {
        height: auto;
        width: auto;
        padding: 6px 13px;
        display: inline-block;
        border-radius: 3px;

        @media only screen and (max-width: 575px) {
          background-color: transparent;
          padding: 6px 0px;
        }

        .back-text {
          gap: 0;
          margin-left: 13px;

          @media only screen and (max-width: 575px) {
            display: none;
          }
        }
      }

      .pckg-item-d {
        align-items: center;

        .pckg-img-d {
          background: #d9d9d9;
          max-width: 280px;
          height: 145px;
          width: 100%;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 992px) {
            max-width: 200px;
            height: 130px;
          }

          @media only screen and (max-width: 575px) {
            max-width: 100%;
            height: auto;
          }
        }

        .pckg-content-d {
          max-width: calc(100% - 280px);
          margin-left: 24px;

          @media only screen and (max-width: 992px) {
            max-width: calc(100% - 200px);
          }

          @media only screen and (max-width: 992px) {
            max-width: 100%;
            margin-left: 0px;
          }

          .pckg-title-d {
            font-size: 30px;

            @media only screen and (max-width: 992px) {
              font-size: 28px;
            }

            @media only screen and (max-width: 767px) {
              font-size: 24px;
              margin-bottom: 0;
            }

            @media only screen and (max-width: 575px) {
              font-size: 21px;
            }
          }

          .packg-desc-d {
            font-size: 16px;
            line-height: 28px;
            max-width: 583px;
            width: 100%;
            font-weight: $font-weight-semibold;
            margin-top: 10px;

            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 21px;
              margin-top: 5px;
            }
          }
        }
      }

      hr {
        border-top: 1px solid #d0d0da;
        opacity: 1;
        margin-bottom: 30px;
      }
    }

    .custom-drawer-body {
      padding-left: 0px;

      @media only screen and (max-width: 1200px) {
        height: 50vh;
      }

      @media only screen and (max-width: 992px) {
        height: 50vh;
      }

      .pckg-content-d {
        .sub-heading {
          margin-bottom: 20px;
          display: block;
        }
      }

      a {
        color: $primary-color !important;
      }

      table {
        width: 100% !important;
        overflow-x: auto !important;
        border: none;

        tr {
          td {
            text-align: left !important;
            padding: 8px !important;
          }
        }
      }

      ol {
        padding-left: 20px;

        li {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;
          color: $secondary-color;

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 22px;
          }

          &:before {
            display: none;
          }
        }
      }

      ul {
        list-style: none;

        li {
          margin-left: 10px;
          padding-left: 20px;
          position: relative;
          color: $secondary-color;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 22px;
            padding-left: 13px;
          }

          &::before {
            content: "";
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background-color: $secondary-color;
            position: absolute;
            left: 0;
            top: 6px;

            @media only screen and (max-width: 767px) {
              height: 6px;
              width: 6px;
              top: 8px;
            }

            @media only screen and (max-width: 575px) {
              height: 5px;
              width: 5px;
              top: 9px;
            }
          }
        }
      }

      ul {
        ol {
          li {
            padding-left: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      p {
        font-size: 14px;
        color: $secondary-color;
        line-height: 18px;
      }

      .pckg-tab-item-d {
        @media only screen and (max-width: 767px) {
          margin-right: 10px;
        }
      }

      .package-include-services {
        margin-top: 40px;
        margin-bottom: 10px;

        @media only screen and (max-width: 414px) {
          margin-top: 10px;
        }

        .package-include-services-list {
          display: flex;
          flex-wrap: wrap;

          .package-include-service-item {
            display: flex;
            align-items: center;
            max-width: 25%;
            width: 100%;
            margin-bottom: 24px;

            @media only screen and (max-width: 1200px) {
              max-width: 30%;
              margin-bottom: 16px;
            }

            @media only screen and (max-width: 992px) {
              max-width: 50%;
              margin-bottom: 16px;
            }

            .package-include-service-img {
              height: 48px;
              width: 48px;
              background: rgba(17, 141, 255, 0.1);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              @media only screen and (max-width: 992px) {
                height: 35px;
                width: 35px;
              }

              @media only screen and (max-width: 767px) {
                height: 24px;
                width: 24px;
              }

              svg {
                @media only screen and (max-width: 992px) {
                  width: 20px;
                }

                @media only screen and (max-width: 767px) {
                  width: 13px;
                }
              }
            }

            .package-include-service-content {
              max-width: calc(100% - 70px);
              width: 100%;
              margin-left: 12px;
              font-size: 14px;
              font-weight: $font-weight-semibold;

              @media only screen and (max-width: 992px) {
                font-size: 12px;
                max-width: calc(100% - 50px);
              }

              @media only screen and (max-width: 767px) {
                font-size: 10px;
                max-width: calc(100% - 36px);
              }
            }
          }
        }
      }
    }
  }
}

// Ant Drawer css for side menubar
.ant-drawer-content-wrapper {
  z-index: 9999;
  @media only screen and (max-width: 414px) {
    width: 90% !important;
  }

  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 16px;
      border-bottom: none;

      .ant-drawer-header-title {
        justify-content: end;
      }

      .ant-drawer-close {
        margin-right: 0px;
      }
    }

    .ant-drawer-body {
      padding: 24px 0px;
      padding-top: 0px;

      .offcanvas-custom {
        .nav-link {
          border-bottom: 1px solid #dadada;
          padding: 10px 15px;
        }

        .header-contact {
          margin: 10px 15px;
          background: rgba(17, 141, 255, 0.1);
          display: flex !important;
          align-items: center;
          background: rgba(17, 141, 255, 0.1);
          padding: 6px;
          border: 1px dashed #6a9b41;
          border-radius: 6px;

          @media only screen and (max-width: 768px) {
            display: flex;
            padding: 10px 15px;
          }

          .contact-icon {
            @media only screen and (max-width: 768px) {
              margin-right: 5px;
              display: flex;
              align-items: center;
            }

            svg {
              @media only screen and (max-width: 768px) {
                height: 17px;
                width: 17px;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.rs-drawer-right.rs-drawer-md {
  @media only screen and (max-width: 768px) {
    width: 95% !important;
  }
}

.gallery-section {
  margin-bottom: 50px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
  }

  .img-gallery-2 {
    height: 320px;
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1199px) {
      height: 250px;
    }

    @media only screen and (max-width: 991px) {
      height: 220px;
    }

    @media only screen and (max-width: 767px) {
      height: 160px;
      border-radius: 10px;
    }

    @media only screen and (max-width: 480px) {
      height: 140px;
      margin-bottom: 15px;
    }

    @media only screen and (max-width: 414px) {
      height: 120px;
    }

    @media only screen and (max-width: 375px) {
      height: 100px;
    }
  }

  .img-gallery-1 {
    margin-bottom: 20px;
    height: 320px;
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1199px) {
      height: 250px;
    }

    @media only screen and (max-width: 991px) {
      height: 220px;
    }

    @media only screen and (max-width: 767px) {
      height: 160px;
      border-radius: 10px;
    }

    @media only screen and (max-width: 480px) {
      height: 140px;
      margin-bottom: 15px;
    }

    @media only screen and (max-width: 414px) {
      height: 120px;
    }

    @media only screen and (max-width: 375px) {
      height: 100px;
    }
  }

  .right-img-gallery {
    .img-gallery-3 {
      margin-bottom: 10px;
      height: 150px;
      overflow: hidden;
      border-radius: 16px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1199px) {
        height: 115px;
        margin-bottom: 10px;
      }

      @media only screen and (max-width: 991px) {
        height: 200px;
        margin-bottom: 10px;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 0;
        height: 100px;
        border-radius: 10px;
      }

      @media only screen and (max-width: 480px) {
        height: 70px;
      }
    }

    .img-gallery-4 {
      margin-top: 10px;
      margin-bottom: 0px;
      height: 150px !important;
      overflow: hidden;
      border-radius: 16px;
      display: flex;
      align-items: center;
      width: 100%;

      @media only screen and (max-width: 1199px) {
        height: 115px !important;
        margin-bottom: 10px;
      }

      @media only screen and (max-width: 991px) {
        margin-top: 0;
        height: 200px !important;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 0;
        height: 100px !important;
        border-radius: 10px;
      }

      @media only screen and (max-width: 480px) {
        height: 70px !important;
      }

      &:before {
        content: "";
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.54);
        left: 0;
        top: 0;
        z-index: 1;
        position: absolute;
      }

      img {
        border-radius: 16px;
      }
    }
  }

  .img-overlay {
    a {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      z-index: 222;

      @media only screen and (max-width: 1199px) {
        font-size: 13px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }

      @media only screen and (max-width: 557px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  img {
    width: 100%;
  }

  .ant-image-mask-info {
    display: flex;
    align-items: center;
  }
}

.mobile-contact {
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
  }

  @media only screen and (max-width: 370px) {
    display: none;
  }

  .bottom-header-right {
    .header-contact {
      background: rgba(17, 141, 255, 0.1);
      display: flex !important;
      align-items: center;
      background: rgba(17, 141, 255, 0.1);
      padding: 6px;
      border: 2px dashed #6a9b41;
      border-radius: 6px;

      @media only screen and (max-width: 992px) {
        margin-top: 0px;
        border-bottom: 1px solid #d8d8d8;
        padding: 6px;
        border: 1px dashed #6a9b41 !important;
      }

      svg {
        margin-right: 15px;
        width: 10px;

        @media only screen and (max-width: 992px) {
          width: 18px;
          margin-right: 5px;
        }

        @media only screen and (max-width: 375px) {
          display: none;
        }
      }

      p {
        position: relative;
        width: auto;
        color: #1c2745;
        line-height: 12px;
        font-weight: 600;

        @media only screen and (max-width: 1100px) {
          font-size: 12px;
          width: auto;
        }

        @media only screen and (max-width: 767px) {
          font-size: 10px;
          display: block;
        }

        @media only screen and (max-width: 600px) {
          width: auto;
        }

        @media only screen and (max-width: 414px) {
          font-size: 8px;
        }
      }

      a {
        font-size: calc(13.2px + 0.875vw);
        color: $primary-color;

        @media only screen and (max-width: 992px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 575px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 375px) {
          font-size: 12px;
        }
      }
    }

    .header-country {
      margin-left: 170px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1400px) {
        margin-left: 80px;
      }

      @media only screen and (max-width: 1100px) {
        margin-left: 50px;
      }

      @media only screen and (max-width: 992px) {
        margin-left: 70px;
      }

      @media only screen and (max-width: 767px) {
        margin-left: 0px;
        margin-top: 0px;
        padding: 15px;
      }

      img {
        margin-right: 15px;
      }

      select {
        border: none;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .navbar-brand {
    margin-right: 0px;
  }
}

.de::after {
  background-color: $primary-color !important;
}

.ct::after {
  border-left-color: transparent !important;
}

.cs::after {
  border-right-color: transparent !important;
}

.cr::after {
  border-bottom-color: transparent !important;
}

.cq::after {
  border-top-color: transparent !important;
}

.btn-sm {
  padding: 13px 45px;

  @media only screen and (max-width: 768px) {
    padding: 10px 45px;
    font-size: 18px;
  }
}

.ant-divider-with-text-left::after,
.ant-divider-with-text-left::before {
  top: 50% !important;
  width: 50% !important;
}

.payment-list-container {
  .ant-spin-container {
    border: 1px solid #ddd;

    .ant-list-item {
      padding: 12px;
    }
  }
}

.list-image-container {
  img {
    width: 80px;

    @media only screen and (max-width: 767px) {
      width: 50px;
    }
  }
}

.visa-desc {
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
  }
}

.site-card-wrapper {
  .Col {
    @media only screen and (max-width: 414px) {
      width: 100%;
    }
  }

  .second-payment-card,
  .first-payment-card {
    @media only screen and (max-width: 767px) {
      //  height: 19rem !important;
      width: 100%;
    }

    @media only screen and (max-width: 414px) {
      //  height: 12rem !important;
    }

    img {
      width: 40%;

      @media only screen and (max-width: 767px) {
        // width: 80% !important;
        margin-bottom: 15px !important;
      }

      @media only screen and (max-width: 414px) {
        // width: 60% !important;
        margin-bottom: 15px !important;
      }
    }
  }

  .third-payment-card {
    // @media only screen and (max-width: 767px) {
    //     width: 40% !important;
    //     margin-bottom: 15px !important;
    // }
    // @media only screen and (max-width: 414px) {
    //     width: 30% !important;
    //     margin-bottom: 15px !important;
    // }
    @media only screen and (max-width: 767px) {
      width: 100% !important;
      margin-bottom: 15px !important;
    }

    img {
      width: 40%;
    }
  }
}

.site-card-wrapper {
  .row {
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  .second-payment-card,
  .first-payment-card,
  .third-payment-card {
    height: 17rem;
    border: 1px solid #eee;

    @media only screen and (max-width: 767px) {
      height: auto;
    }

    // @media only screen and (max-width: 600px) {
    //     height: 17rem;
    // }
    .ant-card-body {
      @media only screen and (max-width: 767px) {
        padding: 14px;
      }

      p {
        @media only screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
    }

    img {
      width: 40%;

      @media only screen and (max-width: 767px) {
        width: 20%;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

  .third-payment-card {
    img {
      width: 40%;

      @media only screen and (max-width: 767px) {
        width: 20%;
      }
    }

    p {
      span {
        @media only screen and (max-width: 375px) {
          display: block;
          margin: 5px 0px !important;
        }
      }
    }
  }

  // .second-payment-card,
  // .first-payment-card {
  //     @media only screen and (max-width: 600px) {
  //         height: 210px;
  //     }
  // }
}

.rs-dropdown-toggle {
  background: none !important;
  margin-top: 2px !important;
  font-weight: 600 !important;
}

.rs-dropdown-item.active {
  background: rgb(16 141 255) !important;
  color: white !important;
}

.rs-dropdown-toggle {
  background: none !important;
  margin-top: 2px !important;
  font-weight: 600 !important;
}

.page-cms {
  background: rgba(17, 141, 255, 0.05);
  margin-bottom: calc(30px + (70 - 30) * ((100vw - 300px) / (1600 - 300)));

  @media only screen and (max-width: 557px) {
    margin-bottom: 50px;
  }

  ol {
    padding-left: 20px;
  }
}

.edit-evisa-btn-container {
  display: flex;
  justify-content: end;
  padding: 5px;
}

.edit-evisa-btn-container > button {
  background: none !important;
  color: #1675e0 !important;
  font-weight: 500 !important;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-steps
  .ant-steps-item-icon
  .ant-steps-icon {
  top: -2.5px !important;
}

// .dropdown-custom-menu {
//     font-size: 12px;
//     color: #6a9b41;
//     padding-bottom: 6px;
// }
.offcanvas .offcanvas-body .bottom-header-right {
  background: linear-gradient(90deg, $primary-color 50%, transparent 50%),
    linear-gradient(90deg, $primary-color 50%, transparent 50%),
    linear-gradient(0deg, $primary-color 50%, transparent 50%),
    linear-gradient(0deg, $primary-color 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 9px 4px, 9px 4px, 4px 8px, 4px 8px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  border-radius: 5px;
  padding: 0px;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

.horizontal-shake {
  animation: horizontal-shaking 5s infinite !important;
}

.trin {
  animation-name: trin !important;
  animation-duration: 5.5s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear;
}

@keyframes trin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  20%,
  32%,
  44%,
  56%,
  68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  23%,
  35%,
  47%,
  59%,
  71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  26%,
  38%,
  50%,
  62%,
  74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  29%,
  41%,
  53%,
  65%,
  77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.offcanvas .offcanvas-body .nav-link:hover {
  color: $primary-color !important;
}

.offcanvas .offcanvas-body .nav-link:hover::before {
  transform: scale(1);
  transition: 0.5s all;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-5px);
  }

  75% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(5px);
  }
}

// Modal CSS
.modal-open {
  .deal-modal {
    .modal-dialog {
      max-width: 750px !important;
      width: 100% !important;

      @media only screen and (max-width: 1000px) {
        max-width: 90% !important;
        margin: 20px auto !important;
      }
    }

    .modal-body-top {
      padding: 30px;

      h4 {
        margin-bottom: 20px;
        font-weight: 700;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 15px;
      }

      .btn-primary-new {
        background-color: #e7f4ff !important;
        color: $primary-color;
        border: 2px solid $primary-color;
        padding: 16px 36px;
        border-radius: 10px;
        font-weight: bold;
        display: inline-block;
        margin-top: 0px !important;

        @media only screen and (max-width: 767px) {
          padding: 8px 25px;
          font-size: 16px;
          margin-top: 0px !important;
        }

        &:hover {
          background-color: $primary-color !important;
          color: $bg-color-white;
        }
      }
    }

    .modal-body-bottom {
      background-color: #f3f9ff;
      //padding: 30px 30px;
      //border-bottom-left-radius: 10px;
      //border-bottom-right-radius: 10px;

      @media only screen and (max-width: 992px) {
        //padding: 20px 20px;
      }

      h6 {
        margin: 24px auto;
        font-weight: $font-weight-semibold;
        text-align: center;
      }

      .modal-footer-services {
        display: flex;

        @media only screen and (max-width: 992px) {
          flex-wrap: wrap;
        }

        .modal-footer-service-item {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 992px) {
            margin-bottom: 15px;
          }

          @media only screen and (max-width: 675px) {
            width: 100%;
          }

          .searvice-img {
            img {
              width: 50px;

              @media only screen and (max-width: 557px) {
                width: 35px;
              }

              @media only screen and (max-width: 414px) {
                width: 25px;
              }
            }
          }

          .searvice-desc {
            margin-left: 15px;
            font-size: 16px;
            font-weight: 500;
            width: calc(100% - 50px);

            @media only screen and (max-width: 557px) {
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

// Call us css //
.social-section {
}

.fixedsocail {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 10px;
  gap: 10px;
  bottom: 10%;
  z-index: 100;

  // @media only screen and (max-width: 2560px) {
  //     bottom: 8.5%;
  //     right:27px;

  //   }

  //   @media only screen and (max-width: 1980px) {
  //     bottom: 10%;
  //     right:28px;

  //   }

  //   @media only screen and (max-width: 1536px) {
  //     bottom: 12.5%;
  //     right:28px;

  //   }

  // @media only screen and (max-width: 1440px) {
  //     bottom: 12%;
  //     right:30px;

  //   }

  //   @media only screen and (max-width: 1024px)  {
  //     bottom: 12%;
  //     right:30px;

  //   }

  //   @media only screen and (max-width: 768px)  {
  //     bottom: 12%;
  //     right:30px;

  //   }

  //   @media only screen and (max-width: 428px)  {
  //     bottom: 12%;
  //     right:30px;

  //   }
}

// @media screen and (max-width: 1024px) {
//         .fixedsocail {
//             bottom: 15%;
//         }
// }

.call-us {
  background: #6a9b41;
  border-radius: 50%;
  bottom: 15%;
  box-shadow: 0 3px 10px -9px gray;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 60px;
  justify-content: center;
  //position: fixed;
  //right: 20px;
  text-align: center;
  transition: 0.25s;
  width: 60px;
  z-index: 555;
  align-items: center;

  &:hover {
    background: #6a9b41 !important;
    color: #fff !important;
  }

  @media only screen and (max-width: 1440px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
    bottom: 15%;
  }

  @media only screen and (max-width: 1024px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }

  @media only screen and (max-width: 768px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }

  @media only screen and (max-width: 428px) {
    height: 55px;
    width: 55px;
    font-size: 20px;
  }

  // &::before,
  // &::after {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     border: 1px solid rgba(255, 255, 255, 0.54);
  //     border-radius: 50%;
  // }
  // &::before {
  //     animation: ripple 2s linear infinite;
  // }
  // &::after {
  //     animation: ripple 2s linear 1s infinite;
  // }
  // @keyframes ripple {
  //     0% {
  //         transform: scale(1);
  //     }
  //     50% {
  //         transform: scale(1.3);
  //         opacity: 1;
  //     }
  //     100% {
  //         transform: scale(1.6);
  //         opacity: 0;
  //     }
  // }
}

.ant-popover {
  font-family: $font-monteserrat !important;
}

.callus-popover-model {
  width: 220px;
  left: inherit !important;
  right: 30px !important;
  position: fixed;

  @media only screen and (max-width: 575px) {
    width: 200px;
    right: 20px !important;
  }

  .ant-popover-inner {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .ant-popover-content {
    .ant-popover-arrow {
      display: block !important;
      left: inherit !important;
      right: 0;

      @media only screen and (max-width: 575px) {
        bottom: 1px !important;
      }
    }

    .ant-popover-inner-content {
      padding: 10px !important;
      font-weight: 500;

      p {
        font-size: 14px !important;

        @media only screen and (max-width: 575px) {
          font-size: 12px !important;
        }

        span {
          padding: 0 !important;
        }
      }
    }
  }
}

.dates-checkbox-container
  .ant-checkbox-wrapper-checked
  .ant-checkbox-checked:after {
  border: 2px solid black !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  //background-color: black;
}

.ant-checkbox .ant-checkbox-inner:after {
  width: 6px !important;
  left: 4px !important;
  top: 7px;
}

.ant-checkbox-wrapper {
  color: #000;
  font-size: 15px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.form-control::placeholder {
  color: #212529 !important;
}

.react-autosuggest__container input[type="text"]::placeholder,
.contact-name .react-autosuggest__container input[type="text"]::placeholder {
  color: #212529 !important;
}

.pl-mobile-0 {
  @media only screen and (max-width: 768px) {
    padding-left: 0px !important;
  }
}

.text-nowrap {
  @media only screen and (max-width: 768px) {
    white-space: inherit !important;
  }
}

.call-image {
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7f7f7;

  img {
    max-width: 75%;
    width: 100%;
    margin: auto;
    text-align: center;

    @media only screen and (max-width: 575px) {
      max-width: 55%;
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

button.call-us {
  &::after {
    content: "";
    animation: zoom 2s linear 0.75s infinite;
    height: 100px;
    width: 100px;
    position: absolute;
    left: -6px;
    right: 47px;
    top: -6px;
    margin: auto;
    border: 3px solid #6a9b41;
    border-radius: 50%;
    z-index: -1;
    animation-delay: 1.25s;

    @media only screen and (max-width: 575px) {
      height: 70px;
      width: 70px;
      position: absolute;
      left: -6px;
      right: 47px;
      top: -6px;
    }
  }

  // &::before {
  //     content: '';
  //     animation: zoom2 4s linear 0.75s infinite;
  //     animation-delay: 1.25s;
  //     height: 110px;
  //     width: 110px;
  //     position: absolute;
  //     left: -12px;
  //     right: 47px;
  //     top: -11px;
  //     margin: auto;
  //     border: 3px solid #6a9b41;
  //     border-radius: 50%;
  // }
}
button.call-us:focus {
  color: white;
}

.whatsapp_float {
  //position: fixed;
  width: 60px;
  height: 60px;
  //bottom: 40px;
  //right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  @media only screen and (max-width: 1440px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }

  @media only screen and (max-width: 1024px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }

  @media only screen and (max-width: 768px) {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }

  @media only screen and (max-width: 428px) {
    height: 55px;
    width: 55px;
    font-size: 20px;
  }
}

.whatsapp-icon {
  margin-top: 10px;

  @media only screen and (max-width: 1440px) {
    margin-top: 8px;
  }

  @media only screen and (max-width: 428px) {
    margin-top: 11px;
  }
}

/* for mobile */
// @media screen and (max-width: 767px) {
//     .whatsapp-icon {
//         margin-top: 10px;
//     }

//     .whatsapp_float {
//         width: 40px;
//         height: 40px;
//         bottom: 20px;
//         right: 10px;
//         font-size: 22px;
//     }
// }

@keyframes zoom {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

// @keyframes zoom2 {
//     0% {
//         opacity: 0;
//     }
//     50% {
//         opacity: 0.9;
//     }
//     100% {
//         opacity: 0;
//     }
// }
.custom-image-gallery {
  .testimonial-list {
    .testimonials-item {
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-bottom: 30px;
      transition: 0.3s all;

      .testimonial-img {
        margin-bottom: 0px;
        position: relative;
        height: 170px;
        overflow: hidden;
        display: flex;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media only screen and (max-width: 575px) {
          height: 350px;
        }

        .overlay-image {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          top: 100%;
          transition: 0.3s all;

          .btn-overlay {
            background-color: #065af3;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 25px;
            padding: 8px 15px;
            width: 95%;
            margin: auto;
            text-align: center;
            transition: 0.3s all;

            .btn-primary {
              font-size: 18px;
            }
          }
        }
      }

      .testimonial-content {
        min-height: 110px;
        padding: 15px;

        @media only screen and (max-width: 575px) {
          min-height: auto;
        }

        p {
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-weight: 500;
        }
      }

      &:hover {
        .overlay-image {
          top: 0;
        }
      }
    }
  }
}

.inner-section-title {
  p {
    font-size: 24px;
  }
}

.ReactGridGallery_tile-viewport {
  position: relative;
  overflow: hidden;
  transition: 0.3s all;

  &:hover {
    .overlay-image {
      top: 100%;
      transition: 0.3s all;
    }
  }
}

.overlay-image {
  position: absolute;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0%;
  transition: 0.3s all;

  .btn-primary {
    width: 65%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}

.ReactGridGallery_tile-viewport {
  position: relative;
  overflow: hidden;
  transition: 0.3s all;

  @media only screen and (max-width: 575px) {
    height: auto !important;
  }

  &:hover {
    .overlay-image {
      top: 100%;
      transition: 0.3s all;
    }
  }

  img {
    @media only screen and (max-width: 575px) {
      width: 100%;
      height: auto !important;
    }
  }
}

.ReactGridGallery_tile {
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 30px !important;
  }
}

.grid-gallery-item_thumbnail {
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}

.row .dates-checkbox-container {
  @media only screen and (max-width: 575px) {
    margin-top: 0px !important;
  }

  label {
    @media only screen and (max-width: 575px) {
      margin-bottom: 15px;
      margin-left: 0px !important;
    }

    &:first-child {
      margin-left: 0px !important;
    }
  }
}

.user-detail-search-form {
  .react-tel-input {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 575px) {
    .date-checkbox {
      margin-top: 0px;
      width: 100%;
      margin-bottom: 10px !important;

      span {
        font-size: 13px !important;
      }
    }
  }
}

.slide-content {
  color: #fff;

  @media only screen and (max-width: 575px) {
    top: 25%;
    position: relative;
  }
}

.each-slide {
  h1 {
    z-index: 555;
    color: #fff;
  }
}

.react-slideshow-container {
  .default-nav {
    height: 50px !important;
    width: 50px !important;
    background: #fff !important;

    // display: none !important;
    &:hover {
      background: #6a9b41 !important;
    }

    @media only screen and (max-width: 575px) {
      top: 40%;
      height: 30px !important;
      width: 30px !important;
    }

    svg {
      height: 18px !important;
      width: 18px !important;

      @media only screen and (max-width: 575px) {
        height: 14px !important;
        width: 14px !important;
      }
    }
  }

  .each-slide-image {
    height: 650px;

    // @media only screen and (max-width: 575px) {
    //     height: 400px;
    //     align-items: normal !important;
    // }
  }
}

.react-slideshow-container .nav:first-of-type {
  left: 50px !important;

  @media only screen and (max-width: 575px) {
    left: 15px !important;
  }
}

.react-slideshow-container .nav:last-of-type {
  right: 50px !important;

  @media only screen and (max-width: 575px) {
    right: 15px !important;
  }
}

.react-slideshow-container .nav:hover svg {
  color: #fff !important;
  fill: #fff !important;
}

.our-product-hide {
  display: none !important;
}

.mb-70 {
  margin-bottom: 70px;
}

.form-floating > .form-select {
  padding-left: 22px;
}

.slide-container {
  //min-height: 650px;
  //@media only screen and (max-width: 575px) {
  //    min-height: 400px;
  //}
}
.theme-text-color {
  color: #6a9b41 !important;
}
